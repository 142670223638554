import { IMenu } from "@/components/navigation/menu.interface";
import PermissionService from "@/services/permission.service";
import UtilService from "@/services/util.service";
import { defineStore } from "pinia";


export const useAppLayoutStore = defineStore({
    id: 'appLayout',
    state: () => ({
        menuItems: [] as IMenu[],
    }),
    actions: {
        async getMenuItems() {
            if (this.menuItems.length > 0) {
                return;
            }
            const response = await fetch(`/common/menu.json`);
            const data = await response.json();
            const tenantType = await UtilService.getTenantType();
            if (!data[tenantType]) {
                console.error(`Tenant type (${tenantType}) not found in menu.json`);
                return;
            }
            const all_menus = [...data.common, ...data[tenantType]];
            const unfiltered_menus = [...all_menus];
            const user_roles = await PermissionService.getRoles();
            const tenant = await PermissionService.getTenantKey();
            const isAgency = await UtilService.isHrAgency();
            let app_menu: IMenu[] = [];
            for (let am of unfiltered_menus) {
                if (am.tenants && am.tenants.length > 0) {
                    if (!am.tenants.includes(tenant)) {
                        continue;
                    }
                }
                if (isAgency && am.always_agency) {
                    app_menu.push(am);
                    continue;
                }

                if (am.permission) {
                    if (am.permission === 'all') {
                        app_menu.push(am);
                    }
                    if (
                        am.permission['admin'] === 'full' &&
                        user_roles.indexOf('admin') >= 0
                    ) {
                        app_menu.push(am);
                    } else if (
                        am.permission['tm'] === 'full' &&
                        user_roles.indexOf('tm') >= 0
                    ) {
                        app_menu.push(am);
                    } else if (
                        am.permission['planner'] === 'full' &&
                        user_roles.indexOf('planner') >= 0
                    ) {
                        app_menu.push(am);
                    } else if (
                        am.permission['planner_woc'] === 'full' &&
                        user_roles.indexOf('planner_woc') >= 0
                    ) {
                        app_menu.push(am);
                    } else if (
                        am.permission['ops'] === 'full' &&
                        user_roles.indexOf('ops') >= 0
                    ) {
                        app_menu.push(am);
                    } else if (
                        am.permission['gateway'] === 'full' &&
                        user_roles.indexOf('gateway') >= 0
                    ) {
                        app_menu.push(am);
                    } else if (
                        am.permission['hr'] === 'full' &&
                        user_roles.indexOf('hr') >= 0
                    ) {
                        app_menu.push(am);
                    }
                } else {
                    app_menu.push(am);
                }
            }
            this.menuItems = app_menu;
        }
    }
});