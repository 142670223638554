<template>
  <div v-if="!appReady" class="loader-wrapper">
    <!-- Fancy spinner, logo, or shimmer -->
    <div class="loader">Loading...</div>
  </div>

  <router-view v-else />
</template>

<script setup lang="ts">
import { watch } from "vue";
import "./assets/css/main.css";
import { useDarkMode } from "./use-dark-mode";
import { ref, onMounted } from 'vue';

const appReady = ref(false);

useDarkMode();

onMounted(() => {
  console.log("App is ready!");
  // Delay just for smoother UX — or use global event instead
  setTimeout(() => {
    console.log("App is ready!");
    appReady.value = true;
  }, 200); // or wait for some store flag like `isRoutesLoaded`
});

</script>

<style>
body,
html {
  margin: 0;
  padding: 0;
  overflow: hidden;
  height: 100%;
}

* {
  box-sizing: border-box;
}

.loader-wrapper {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

</style>