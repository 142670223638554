import AuthService from "./auth.service";
import FirebaseService from "./firebase.service";

export enum CompanyType {
  HR_AGENCY = "hr_ag",
  CARRIER = "carrier",
  SYSTEM = "system",
  SUBCONTRACTOR = "subcontractor",
}

export default class UtilService {
  /**
   * 
   * @param what check tenant type
   * @returns 
   */
  static async isTenant(what = "") {
    const loggedUser = await FirebaseService.getUserData();
    return loggedUser.company_type === what;
  }

  /**
   * get tenant key
   * @returns 
   */
  static async getTenantType() {
    const loggedUser = await FirebaseService.getUserData();
    if (!loggedUser) {
      return "";
    }
    return loggedUser.company_type;
  }

  /**
   * @deprecated
   *  Please use isTenant instead
   * @returns 
   */
  static async isHrAgency() {
    return UtilService.isTenant(CompanyType.HR_AGENCY);
  }

  static async isDoitdev() {
    const loggedUser = await FirebaseService.getUserData();
    return loggedUser.tenant_key === "doitdev";
  }

  static async isDlg1() {
    const loggedUser = await FirebaseService.getUserData();
    return loggedUser.tenant_key === "dlg1";
  }

  static async isLivi() {
    const loggedUser = await FirebaseService.getUserData();
    return loggedUser.tenant_key === "livi";
  }

  static async isAdmin() {
    const loggedUser = await FirebaseService.getUserData();
    return loggedUser.user_attr.roles.includes("admin");
  }

  static async isSlam() {
    const loggedUser = await FirebaseService.getUserData();
    return loggedUser.tenant_key === "slam";
  }

  static formatDateToYYYYMMDD(date: Date): string {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // getMonth() returns 0-11
    const day = String(date.getDate()).padStart(2, "0"); // getDate() returns 1-31

    return `${year}-${month}-${day}`;
  }

  static formatDate(date: Date, options: Intl.DateTimeFormatOptions): string {
    const d = date || new Date();
    const opt = options || {
      year: "numeric",
      month: "short",
      day: "numeric",
    };
    return d.toLocaleDateString("en-US", opt);
  }

  static niceBytes(x: number | string): string {
    const units = ["bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    let n = parseInt(x.toString(), 10) || 0;
    let l = 0;

    while (n >= 1024 && ++l) {
      n = n / 1024;
    }

    return `${n.toFixed(n < 10 && l > 0 ? 1 : 0)} ${units[l]}`;
  }

  public static generateInitials(displayName: string): string {
    const names = displayName.trim().split(" ");
    const initials = names.map((name) => name[0]).join("");
    return initials.toUpperCase();
  }

  public static capitalizeFirstLetter(text: string) {
    return text.charAt(0).toUpperCase() + text.toLowerCase().slice(1);
  }

  static jsonToCSV(jsonArray: Record<string, any>[]): string {
    if (jsonArray.length === 0) {
      return "";
    }

    const headers = Object.keys(jsonArray[0]).join(",") + "\n";

    const rows = jsonArray
      .map((obj) => {
        const values = Object.values(obj)
          .map((value) => `"${String(value).replace(/"/g, '""')}"`)
          .join(",");
        return values;
      })
      .join("\n");

    return headers + rows;
  }

  static downloadCSV(csvString: string, filename = "export.csv"): void {
    const blob = new Blob([csvString], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute("download", filename);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  }

  /*
  @type - check / fuel / defects
   */
  static buildStoragePhotoUrl(
    type: string,
    photo_name: string,
    company_id: string,
  ) {
    const bucket = AuthService.storage.app.options.storageBucket;
    const baseUrl = `https://firebasestorage.googleapis.com/v0/b/${bucket}/o/`;

    return `${baseUrl}${company_id}%2F${type}%2F${photo_name}.jpg?alt=media`;
  }

  static getActiveTabClass(tabString: string, tab: string) {
    return tabString === tab ? "active-tab" : "";
  }

  static showSnackbar = (qs: any, message: string) => {
    qs.notify({
      message: message,
      timeout: 2000,
      color: "black",
      textColor: "white",
      position: "bottom-right",
      actions: [
        {
          icon: "close",
          color: "white",
          handler: () => { },
        },
      ],
    });
  };
}
