import AuthService from "./auth.service";
import ClientStorage from "./client-storage.service";

const handleResponse = async (response: Response, isBlob = false) => {
  let data;
  if (isBlob) {
    data = await response.blob();
  } else {
    data = await response.json();
  }

  if (!response.ok) {
    // Handle HTTP errors
    const error = (data && data.error) || response.statusText;
    console.log(error);
    return Promise.reject(error);
  }
  return data;
};

const handleError = async (error: any) => {
  console.log(error);
  if (error.code === '23502') {
    alert('Db Error: ' + error);
  } else if (error.code === 'no_auth') {
    alert('Session expired, or lost!');
    const as = new AuthService();
    await as.logout();
  } else if (error.code === 'invalid_credentials') {
    alert('Invalid credentials!');
  }
  // console.error(`Error: ${error.description}.\nPlease contact the development team!`);
  return Promise.reject(error);
};

const HttpService = {
  baseUrl: "/api/v1",

  getBaseUrl(url: string): string {
    return this.baseUrl + url
  },

  async getHeader() {
    const as = new AuthService();
    const tk = await as.getIdToken();
    const headers: HeadersInit = {
      "Content-Type": "application/json"
    };
    if (tk && tk !== '') {
      headers['Authorization'] = 'Bearer ' + tk || ''
    }
    return headers;
  },

  async remove(url: string): Promise<any> {
    const headers = await this.getHeader();
    const response = await fetch(this.getBaseUrl(url), {
      method: 'DELETE',
      headers,
    });
    return handleResponse(response).catch(handleError);
  },

  async post(url: string, data: any): Promise<any> {
    const headers = await this.getHeader();
    const response = await fetch(this.getBaseUrl(url), {
      method: 'POST',
      headers,
      body: JSON.stringify(data),
    });
    return handleResponse(response).catch(handleError);
  },

  async put(url: string, data: any): Promise<any> {
    const headers = await this.getHeader();
    const response = await fetch(this.getBaseUrl(url), {
      method: 'PUT',
      headers,
      body: JSON.stringify(data),
    });
    return handleResponse(response).catch(handleError);
  },

  // Add other HTTP methods (GET, PUT, DELETE, etc.) here as needed
  async get(url: string): Promise<any> {
    const response = await fetch(this.getBaseUrl(url), {
      method: 'GET',
      headers: await this.getHeader(),
    });
    return handleResponse(response).catch(handleError);
  },

  async upload(url: string, formData: any): Promise<any> {
    const headers = await this.getHeader();
    delete headers['Content-Type'];
    const response = await fetch(this.getBaseUrl(url), {
      method: 'POST',
      headers: headers,
      body: formData,
    });
    return handleResponse(response).catch(handleError);
  },

  async download(url: string): Promise<any> {
    const response = await fetch(this.getBaseUrl(url), {
      method: 'GET',
      headers: await this.getHeader(),
    });
    return handleResponse(response, true).catch(handleError);
  },
};

export default HttpService;
